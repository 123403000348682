/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { Table, Card, Form, InputGroup, Alert } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import unionBy from 'lodash/unionBy';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cls from 'classnames';
//import processGTMevent from '../../../../util/processGTMEvent';
import ExpenseForm from '../ExpenseForm';
import { amountPerMonth } from '../../../../lib/formatter';
import HelpIcon from '@material-ui/icons/Help';
import rightnormal from '../../../../../assets/image/right-normal.svg';
import { DELETE_CONFIRM, RENTER, MONTH_OPTION, FREQ_OPTIONS } from '../../../../util/constants';
import alert from '../../../../../assets/image/risk.svg';
import { getNameByValue } from '../../../../util/ContainerUtilities';
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import ConfirmDeleteRow from '../../../../components/ConfirmDeleteRow';
import deleteImg from '../../../../../assets/image/delete.svg';
import '../../finances-common.scss';
import './expensedetailsNew.scss';

const ACTION_NEW = 'NEW';
const RENT_EXPENSE = 'rent';
export const OPTIONAL_EXPENSES = [
  { name: 'Rent', value: RENT_EXPENSE },
  { name: 'Childcare & Education', value: 'child&Education' },
  { name: 'Other', value: 'other' },
];
export const MANDATORY_EXPENSES = [
  { name: 'Rent', value: RENT_EXPENSE },
  { name: 'Groceries', value: 'Groceries' },
  { name: 'Utilities', value: 'Utilities' },
  { name: 'Phone & Internet', value: 'PhoneInternet' },
  { name: 'Entertainment', value: 'Entertainment' },
  { name: 'Personal & Clothing', value: 'PersonalClothing' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Insurance', value: 'Insurance' },
];

export const MANDATORY_EXPENSES_RENT = [
  { name: 'Groceries', value: 'Groceries' },
  { name: 'Utilities', value: 'Utilities' },
  { name: 'Phone & Internet', value: 'PhoneInternet' },
  { name: 'Entertainment', value: 'Entertainment' },
  { name: 'Personal & Clothing', value: 'PersonalClothing' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Insurance', value: 'Insurance' },
];
export const ExpenseTypes = [...OPTIONAL_EXPENSES, ...MANDATORY_EXPENSES];

const ExpenseDetailsNew = ({
  expenses,
  saveExpensesApi,
  ValidationErrorFlag,
  addExpense,
  updateExpense,
  deleteExpense,
  expenseCompleted,
  completeExpenseDetails,
  deleteExpApi,
  reQuote,
  liabilities,
  residentialStatus,
  validationCheck,
  errorMessage,
  noErrors,
  change,
  retrieveOpportunity
}) => {
  const [showForm, updateShowForm] = useState(false);
  const [expensesList, updateList] = useState(expenses || []);
  const [deleteExp, updateDeleteExp] = useState(false);
  const [selectedFreq, updateSelectedFreq] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    const filteredList = reduce(
      expensesList,
      (accum, stateExpenses) => {
        const sourceIncome = find(expenses, ['id', stateExpenses.id]);
        if (sourceIncome) {
          accum.push({ ...stateExpenses, ...sourceIncome, flagDelete: stateExpenses.flagDelete, });
        }
        return accum;
      },
      [],
    );
    const filtredExpenses = residentialStatus !== 'Renting' ? unionBy(filteredList, expenses, 'id').filter(function (obj) {
      return obj.category !== RENT_EXPENSE;
    }) : unionBy(filteredList, expenses, 'id')

    updateList(filtredExpenses);
  }, [expenses]);

  const flagToDelete = (id, flag = false) => () => {
    updateList(
      reduce(
        expensesList,
        (accum, option) => {
          const updatedOption = { ...option };
          if (option.id === id) {
            updatedOption.flagDelete = flag;
          }

          accum.push(updatedOption);
          return accum;
        },
        [],
      ),
    );
  };

  const totalLiabilityRepayments = round(
    reduce(
      liabilities,
      (calTotal, liabilityData) =>
        calTotal +
        Number(
          liabilityData.brokerUpdated
            ? liabilityData.verifiedMonthlyAmount
            : amountPerMonth(liabilityData.repayments, liabilityData.frequency),
        ),
      0,
    ),
  );

  const expensetotal = round(
    reduce(
      expenses,
      (calTotal, expenseData) =>
        calTotal +
        Number(
          expenseData.brokerUpdated
            ? expenseData.verifiedMonthlyAmount
            : amountPerMonth(expenseData.amount, expenseData.frequency),
        ),
      0,
    ),
  );
  const handleAddExpense = () => {
    updateShowForm(ACTION_NEW);
  };

  const handleDeleteExpense = (expenseData) => () => {
    updateDeleteExp(true)
    deleteExpApi({ ...expenseData })
      .then(() => {
        deleteExpense({ ...expenseData });
        updateDeleteExp(false)
      })
      .catch((err) => {
        updateDeleteExp(false)
        console.log('error');
      });
  };

  const handleCancel = () => {
    updateShowForm('');
  };

  const updateExpensesValue = (data, freq) => {
    //processGTMevent('finances', 'expenses', 'addExpenses');
    updateExpense({ ...data, frequency: freq })
  }

  const handleChange = (data, e) => {
    //processGTMevent('finances', 'expenses', 'addExpenses');
    updateExpense({ ...data, amount: e.target.value });
  }

  const handleBlur = (data, e) => {
    //processGTMevent('finances', 'expenses', 'addExpenses');
    const amtountVal = e.target.value;
    let errorTxt = undefined
    if (!amtountVal) {
      errorTxt = 'Required';
    }
    else if (Number.isNaN(amtountVal)) {
      errorTxt = 'Must be a number';
    }
    else if (Number(amtountVal) < 1) {
      errorTxt = 'amount should be greater than one';
    }
    updateExpense({ ...data, error: errorTxt });
  }

  const onClickFrequency = (freq) => {
    updateSelectedFreq(freq)
    expensesList.forEach((item) => {
      const expenseItem = { ...item, frequency: item.reasonForChange ? 'Monthly' : freq }
      updateExpense(expenseItem)
      change(`expenseFreqFormPage_${item.category}`, `frequency`, freq)
    })
  };

  const onChangeFrequency = (e, data) => {
    updateExpense({ ...data, frequency: e.target.value })
  }

  const onUpdateExpsenes = (expenseData) => {
    if (showForm === ACTION_NEW) {
      addExpense(expenseData);
    } else {
      updateExpense(expenseData);
    }
    handleCancel();
  };

  const handleDeleteFile = (expenseData) => {
    updateExpense(expenseData);
    updateShowForm({ ...expenseData });
  };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const selectExp = expensesList[index + 1]
      const nextIndex = (selectExp && selectExp?.reasonForChange) ? index + 2 : index + 1;

      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
      } else {
        inputRefs.current[0].focus();
      }
    }
  };
  const addInputRef = (ref, index) => {
    if (ref && !inputRefs.current.includes(ref)) {
      inputRefs.current.push(ref);
      if (index === inputRefs.current.length - 1) {
        ref.onkeydown = (e) => handleKeyDown(e, index);
      }
    }
  };
  const isMandatoryExpense = (category) => find(residentialStatus !== 'Renting' ? MANDATORY_EXPENSES_RENT : MANDATORY_EXPENSES, ['value', category]);

  const handleExpenseDetails = () => {
    // processGTMevent('finances', 'totalexpenses', { value: expensetotal });
    reQuote();
    const expenseRenting = find(expenses, ['category', RENT_EXPENSE]);
    const mandatoryExpenses = filter(
      expenses,
      (expAmt) =>
        (expAmt.amount === '0.00' || expAmt.amount === '0' || expAmt.amount === '') && isMandatoryExpense(expAmt.category),
    );
    if (residentialStatus === RENTER && !expenseRenting) {
      validationCheck(
        `${getNameByValue(
          ExpenseTypes,
          RENT_EXPENSE,
        )} is mandatory due to your current living situation`,
      );
    } else if (
      residentialStatus === RENTER &&
      expenseRenting &&
      (!expenseRenting.amount || expenseRenting.amount === '0' || expenseRenting.amount === '0.0')
    ) {
      validationCheck(
        `${getNameByValue(ExpenseTypes, RENT_EXPENSE)} expense should be greater than zero`,
      );
    } else if (mandatoryExpenses?.length > 0) {
      validationCheck('Expenses should be greater than zero');
    } else {
      noErrors();
      completeExpenseDetails();

      saveExpensesApi(residentialStatus).then(() => {
        retrieveOpportunity()
      })
        .catch(() => {
          retrieveOpportunity()
        })
    }
  };
  const smScreen = useMediaQuery('@media (max-width: 767.95px)');
  return (
    <Card className="content-body expense-details finances-details-container expense-details">
      <Card.Header className="col-12 page-header-container finances-details-header-container scrollable-anchor-tag with-sidebar">
        <Grid direction='row' container spacing={1}>
          <Grid container item sm={8} md={8}>
            <a name="expense-details" href="#expense-details">
              &nbsp;
            </a>
            <h1 className="finances-details-header">Your house expense details</h1>
            <div className="finances-details-description">
              We need your entire household expenses (including your partner/family)
            </div>
          </Grid>
          <Grid container item sm={4} md={4}>
            <h1 className='finances-frequency-header'>Apply frequency for all expenses</h1>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              {FREQ_OPTIONS.map((item, index) => {
                return (<Button onClick={() => { onClickFrequency(item.name) }} key={`button_${index}`} className={`btn-freq-group ${item.name === selectedFreq ? 'selectedButton' : ''}`}>{item.name}</Button>)
              })}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Card.Header>

      <Card.Body className={cls('finances-body-container', !ValidationErrorFlag && 'pt-0')}>
        {expenses.length === 0 && liabilities.length === 0 && (
          <div className="netincome">
            <div className="netincome-label">
              <button
                type="button"
                onClick={handleAddExpense}
                className="btn btn-add-income add-expense"
              >
                + Add expense
              </button>
            </div>
          </div>
        )}
        {ValidationErrorFlag && (
          <Alert variant="danger" className="error-alert">
            <p className="error-message">{errorMessage}</p>
          </Alert>
        )}

        {smScreen && <Grid container className='expenses-card-table-mobile-header'>
          <Grid item xs={6}>
            <span>Amount</span>
          </Grid>
          <Grid item xs={6}>
            <span>Frequency</span>
          </Grid>
        </Grid>}
        <Table hover responsive className="finances-body-table">
          {!smScreen && <thead class="table-light">
            <tr key={`header${-1}`} className='expenses-card-table-header'>
              <th className="header-padding">Expenses</th>
              {/* <th>Help</th> */}
              <th>Amount</th>
              <th>Frequency</th>
              <th />
            </tr>
          </thead>}
          <tbody>
            {liabilities.length > 0 && (
              <tr key={`libilities${-1}`} className="expenses-card-table-row">
                {smScreen ? <td colSpan={4}>
                  <Grid container spacing={2} className='expenses-card-table-detail-mobile'>
                    <Grid item xs={6}> Liability Repayments</Grid>
                    <Grid item xs={6} className='align-text'>  <NumberFormat
                      displayType="text"
                      value={totalLiabilityRepayments}
                      prefix="$"
                      suffix=" /m"
                      thousandSeparator
                      decimalScale={2}
                    /></Grid>
                  </Grid>
                </td> : <> <td className="expenses-card-table-detail">
                  Liability Repayments
                </td>
                  <td className="expenses-card-table-detail">
                    <NumberFormat
                      displayType="text"
                      value={totalLiabilityRepayments}
                      prefix="$"
                      suffix=" /m"
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td></>}
              </tr>
            )}

            {expensesList.map((task, index) => (
              <tr key={`table-data${task.id}`} className="expenses-card-table-row">
                {smScreen && !task.flagDelete && <td colSpan={4}>
                  <Grid container spacing={2} className='expenses-card-table-detail-mobile'>
                    <Grid item xs={6}>
                      {get(find(ExpenseTypes, ['value', task.category]), 'name', 'N/A')}
                      <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                          placeholder="0.00"
                          type='number'
                          aria-label="amount"
                          value={task.reasonForChange ? task.verifiedMonthlyAmount : task.amount !== '0.00' ? task.amount : ''}
                          index={index}
                          ref={(ref) => addInputRef(ref, index)}
                          onChange={(e) => { handleChange(task, e) }}
                          onBlur={(e) => { handleBlur(task, e) }}
                          disabled={task.reasonForChange}
                        />
                        {task.error && <Form.Control.Feedback type="invalid">
                          {task.error}
                        </Form.Control.Feedback>}
                      </InputGroup>
                    </Grid>
                    <Grid item xs={6} className='item-align'>
                      <Form.Group xs={6}>
                        <Form.Control
                          as="select"
                          id='frequency'
                          defaultValue={task.frequency}
                          onChange={(e) => {
                            onChangeFrequency(e, task)
                          }}
                          onBlur={(e) => {
                            onChangeFrequency(e, task)
                          }}
                          value={task.frequency || ''}
                          disabled={task.reasonForChange}
                        >
                          {FREQ_OPTIONS.map((item) => {
                            return (<option value={item.value}>{item.name}</option>)
                          })}
                        </Form.Control>
                      </Form.Group>
                      {!isMandatoryExpense(task.category) && (
                        <>
                          <img src={deleteImg} alt="delete" />{' '}
                          <button
                            type="button"
                            className="px-0 btn btn-link finances-card-table-delete"
                            onClick={flagToDelete(task.id, true)}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </td>}

                {/* <td className="expenses-card-table-detail"> <HelpIcon style={{ color: '#00c1d5', width: '21px', height: '21px' }} /></td> */}

                {smScreen && <ConfirmDeleteRow
                  task={task}
                  deleteConfirmText={DELETE_CONFIRM}
                  deleteExp={deleteExp}
                  onDelete={handleDeleteExpense}
                  flagToDelete={flagToDelete}
                />}

                {!smScreen && <> <td className="expenses-card-table-detail">{get(find(ExpenseTypes, ['value', task.category]), 'name', 'N/A')}</td></>}
                {!smScreen && !task.flagDelete && (<>
                  <td className="expenses-card-table-detail">
                    <InputGroup>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        placeholder="0.00"
                        type='number'
                        aria-label="amount"
                        value={task.reasonForChange ? task.verifiedMonthlyAmount : task.amount !== '0.00' ? task.amount : ''}
                        index={index}
                        ref={(ref) => addInputRef(ref, index)}
                        onChange={(e) => { handleChange(task, e) }}
                        onBlur={(e) => { handleBlur(task, e) }}
                        disabled={task.reasonForChange}
                      />
                      {task.error && <Form.Control.Feedback type="invalid">
                        {task.error}
                      </Form.Control.Feedback>}
                    </InputGroup>
                  </td>
                  <td className="expenses-card-table-detail">
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                      {FREQ_OPTIONS.map((item, index) => {
                        return (<Button onClick={() => { updateExpensesValue(task, item.name) }} key={`button_${index}`} className={`btn-freq-group freq-show ${item.name === task.frequency ? 'selectedButton' : ''}`} disabled={task.reasonForChange}>{item.name}</Button>)
                      })}
                    </ButtonGroup>
                  </td>
                  <td className="expenses-card-table-detail btn-delete">
                    {!isMandatoryExpense(task.category) && (
                      <>
                        <img src={deleteImg} alt="delete" />{' '}
                        <button
                          type="button"
                          className="px-0 btn btn-link finances-card-table-delete"
                          onClick={flagToDelete(task.id, true)}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </>)}
                <ConfirmDeleteRow
                  task={task}
                  deleteConfirmText={DELETE_CONFIRM}
                  deleteExp={deleteExp}
                  onDelete={handleDeleteExpense}
                  flagToDelete={flagToDelete}
                />
              </tr>))}
            {expenses.length > 0 && (
              <tr key={`expenses${-2}`} className="expenses-card-table-row">

                {smScreen ? <td className="label-font" colSpan={4}>
                  <Grid container spacing={2} className='expenses-card-table-detail-mobile'>
                    <Grid item xs={6}>Total expenses</Grid>
                    <Grid item xs={6} className='align-text'>   <NumberFormat
                      displayType="text"
                      value={expensetotal + totalLiabilityRepayments}
                      prefix="$"
                      suffix=" /m"
                      thousandSeparator
                      decimalScale={2}
                    />
                    </Grid>
                  </Grid>
                </td> : <> <td className="expenses-card-table-total label-font">
                  Total expenses
                </td>
                  <td
                    className="expenses-card-table-total"
                  >
                    <NumberFormat
                      displayType="text"
                      value={expensetotal + totalLiabilityRepayments}
                      prefix="$"
                      suffix=" /m"
                      thousandSeparator
                      decimalScale={2}
                    />
                  </td></>}
              </tr>
            )}
          </tbody>

          {((expenses && expenses.length !== 0) || (liabilities && liabilities.length > 0)) && (
            <button
              type="button"
              className="btn btn-primary finances-details-button"
              onClick={handleAddExpense}
            >
              <span className="btn-income add-expense">+ Add expense</span>
            </button>
          )}
        </Table>

        {false && (
          <>
            <hr className="border-align-warning" />
            <div className="expense-warning">
              <img src={alert} alt="alerticon" className="alert-icon" />
              <div className="warning-message">
                Your total living expenses appear lower than expected, please review before
                proceeding
              </div>
            </div>
          </>
        )}

        {!!showForm && (
          <ExpenseForm
            isAdd={showForm === ACTION_NEW}
            initialValues={
              showForm === ACTION_NEW
                ? { frequency: MONTH_OPTION, category: '', amount: '' }
                : showForm
            }
            expenseDetails={showForm === ACTION_NEW ? '' : showForm}
            onUpdateExpense={onUpdateExpsenes}
            onCancel={handleCancel}
            onDelete={handleDeleteFile}
          />
        )}

        <div className=" container-footer py-3 px-3 border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              className="btn btn-primary btn-next-page expense-next-section"
              // disabled={expensetotal <= 0}
              hidden={expenseCompleted}
              onClick={handleExpenseDetails}
            >
              Next
              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>
            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExpenseDetailsNew;
